import React, { useEffect, useState } from "react";
import { useLocation, Route, Switch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { getPendingPreClosureRequestsInfo } from "redux/actions/admin/PendingPreClosureAction";
import { fetchRole } from "helpers/storageData";
import { adminRoutes } from "routes";
import { superAdminRoutes } from "routes";
import { managerRoutes } from "routes";
import io from "socket.io-client";
import { useDispatch } from "react-redux";
import { setCompanyBookingApprovalInfo } from "redux/actions/admin/BookingApprovalRequestAction";
import { setPendingRequestInfo } from "redux/actions/admin/PendingRequestAction";
import { getApprovedRequestsInfo } from "redux/actions/admin/ApprovedRequestAction";
import { getRejectedRequestsInfo } from "redux/actions/admin/RejectedRequestAction";
import { setCompanyBookingExtentionInfo } from "redux/actions/admin/BookingExtentionRequestAction";
import toast from "../components/Toastify";
import { setNotificationList } from "redux/actions/admin/NotificationListRequestAction";
import { fetchUserData } from "helpers/storageData";
import { setPreClosureRequestInfo } from "redux/actions/admin/PreClosureRequestAction";
import { baseURL } from "api/Request";
import { setSowInfo } from "redux/actions/admin/SowActions";
import { fetchToken } from "helpers/storageData";

const Admin = (props) => {
  const dispatch = useDispatch();
  let token = fetchToken();

  if (token === null) {
    // localStorage.clear();
    dispatch({
      type: "STORE_IS_LOGOUT",
      payload: false,
    });

    window.location.reload();
  }
  useEffect(() => {
    dispatch({
      type: "NOTIFICATION_RESET",
    });
    dispatch({
      type: "ALL_REQUEST_LIST_RESET",
    });
    dispatch({
      type: "ALL_BOOKING_LIST_RESET",
    });
    dispatch({
      type: "ALL_BOOKING_EXTENTION_LIST_RESET",
    });
    dispatch({
      type: "PRE_CLOSURE_REQUEST_LIST_RESET",
    });
    // "ALL_BOOKING_EXTENTION_LIST_RESET"
    // ALL_REQUEST_LIST_RESET
    // ALL_BOOKING_LIST_RESET
    // PRE_CLOSURE_REQUEST_LIST_RESET
    // eslint-disable-next-line
  }, [props]);
  const [routes, setRoutes] = useState([]);
  const role = fetchRole();
  const user = localStorage.getItem("user");
  const userData = JSON.parse(user);

  useEffect(() => {
    switch (role) {
      case "super_admin":
        setRoutes(superAdminRoutes);

        break;
      case "company_manager":
        setRoutes(managerRoutes);

        break;
      case "company_admin":
        setRoutes(adminRoutes);

        break;

      default:
        setRoutes([]);
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get Notifications
  const getNotification = () => {
    dispatch(setNotificationList(fetchUserData()?.userId));
  };

  useEffect(() => {
    if (role) getNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  // Initiating socket connection
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    console.log("setting up socket connection");
    const newSocket = io(baseURL);
    setSocket(newSocket);
    return () => {
      newSocket.close();
      dispatch({
        type: "STORE_SOCKET_INSTANCE_RESET",
        payload: null,
      });
    };
  }, [setSocket, dispatch]);

  const messageListener = (data) => {
    //first letter caps in notification
    // let message = data?.message[0].toUpperCase() + data?.message.slice(1);
console.log("dataaa",data)
debugger;
    dispatch(setNotificationList(fetchUserData()?.userId));
    switch (data?.type) {
      case "UPDATE_PENDING_BOOKING_APPROVAL_REQUESTS":
        // toast.success(message);
        toast.success(data?.message);
        dispatch(setCompanyBookingApprovalInfo("0", "1", "pending", null));
        break;
      case "UPDATE_PENDING_INTERVIEW_APPROVAL_REQUESTS":
        // toast.success(message);
        toast.success(data?.message);
        dispatch(setCompanyBookingApprovalInfo("0", "1", "pending", null));
        break;
      case "CREATE_BOOKING_CLOSURE_REQUEST":
        toast.success(data?.message);
        dispatch(setPreClosureRequestInfo(null));
        break;
      case "CREATE_SLOT_CHANGE_REQUEST":
        toast.success(data?.message);
        dispatch(setCompanyBookingApprovalInfo("0", "1", "pending", null));
        break;
      case "REJECT_SLOT_CHANGE_REQUEST":
        toast.success(data?.message);
        dispatch(getApprovedRequestsInfo("1", "1", "", null));
        dispatch(getApprovedRequestsInfo("1", "1", "pending", null));
        break;
      case "ACCEPT_SLOT_CHANGE_REQUEST":
        toast.success(data?.message);
        dispatch(getApprovedRequestsInfo("1", "1", "", null));
        dispatch(getApprovedRequestsInfo("1", "1", "pending", null));
        break;
      case "ACCEPT_CANDIDATE":
        toast.success(data?.message);
        dispatch(getApprovedRequestsInfo("1", "1", "", null));
        dispatch(getApprovedRequestsInfo("1", "1", "pending", null));
        break;
      case "REJECT_CANDIDATE":
        toast.success(data?.message);
        dispatch(getApprovedRequestsInfo("1", "1", "", null));
        dispatch(getApprovedRequestsInfo("1", "1", "pending", null));
        break;
      case "CREATE_CONFIRM_SLOT_REQUEST":
        toast.success(data?.message);
        dispatch(setCompanyBookingApprovalInfo("0", "1", "pending", null));
        break;
      case "UPDATE_REJECT_INTERVIEW":
        toast.success(data?.message);
        dispatch(setCompanyBookingApprovalInfo("0", "1", "pending", null));
        break;
      case "CANCEL_INTERVIEW_REQUEST":
        toast.success(data?.message);
        dispatch(setCompanyBookingApprovalInfo("0", "1", "pending", null));
        break;

      case "UPDATE_CLOSURE_APPROVED_REQUEST_LIST":
        toast.success(data?.message);
        dispatch(getApprovedRequestsInfo("1", "1", "approved", null, null));

        break;
      case "UPDATE_CLOSURE_REJECTED_REQUEST_LIST":
        toast.success(data?.message);
        dispatch(getApprovedRequestsInfo("1", "1", "approved", null, null));
        break;
      case "UPDATE_APPROVED_BOOKING_LIST":
        //making company name first letter caps
        // let str = data?.message;
        // const exclamationIndex = str.indexOf("!"); // Find the index of the exclamation mark
        // const term = str.substring(exclamationIndex + 2); // Get the term after the exclamation mark
        // const capitalizedTerm = term.charAt(0).toUpperCase() + term.slice(1); // Capitalize the term
        // const reframedStr =
        //   str.substring(0, exclamationIndex + 2) + capitalizedTerm; // Reframe the string

        // toast.success(reframedStr);
        toast.success(data?.message);
        dispatch(setPendingRequestInfo("1", "1", null, null));
        dispatch(getApprovedRequestsInfo("1", "1", "approved", null, null));

        break;
      case "UPDATE_REJECTED_BOOKING_LIST":
        // toast.success(message)
        toast.success(data?.message);
        dispatch(setPendingRequestInfo("1", "1", null, null));
        dispatch(getRejectedRequestsInfo("1", "1", "rejected", null, null));

        break;
      case "UPDATE_SOW_APPROVED_REQUEST_LIST":
        // toast.success(message);
        toast.success(data?.message);
        dispatch(getApprovedRequestsInfo("1", "1", "approved", null, null));
        break;
      case "UPDATE_SOW_REJECTED_REQUEST_LIST":
        toast.success(data?.message);
        dispatch(getApprovedRequestsInfo("1", "1", "approved", null, null));
        break;
      case "UPDATE_DELETE_BOOKING":
        toast.success(data?.message);
        // dispatch(setPendingRequestInfo("1", "1", null, null));
        dispatch(setCompanyBookingApprovalInfo("0", "1", "pending", null));
        break;

      case "UPDATE_PENDING_BOOKING_EXTENTION_APPROVAL_LIST":
        toast.success(data?.message);
        dispatch(setCompanyBookingExtentionInfo("0", "1", "pending", null));

        break;

      case "UPDATE_APPROVED_BOOKING_EXTENTION_LIST":
        toast.success(data?.message);
        dispatch(setCompanyBookingExtentionInfo("1", "1", "approved", null));
        dispatch(setCompanyBookingExtentionInfo("1", "1", "pending", null));
        dispatch(getApprovedRequestsInfo("1", "1", "approved", null, null));

        break;
      case "UPDATE_REJECTED_BOOKING_EXTENTION_LIST":
        toast.success(data?.message);
        dispatch(setCompanyBookingExtentionInfo("1", "1", "rejected", null));
        dispatch(getApprovedRequestsInfo("1", "1", "approved", null, null));

        break;
      case "UPDATE_DELETE_BOOKING_EXTENTION":
        toast.success(data?.message);
        dispatch(setCompanyBookingExtentionInfo("0", "1", "pending", null));

        break;
      case "UPDATE_DELETE_BOOKING_PRE-CLOSURE":
        toast.success(data?.message);
        dispatch(getPendingPreClosureRequestsInfo("1", "1", null, "", " "));
        break;

      case "UPDATE_FINAL_SOW_APPROVED_REQUEST_LIST":
        // toast.success(message);
        toast.success(data?.message);
        dispatch(setSowInfo("0", "1", "approved", null, "pending"));
        break;

      case "UPDATE_FINAL_SOW_REJECTED_REQUEST_LIST":
        // toast.success(message);
        toast.success(data?.message);
        dispatch(setSowInfo("0", "1", "approved", null, "pending"));
        break;

      case "RESUME REQUEST":
        // toast.success(message);
        toast.success(data?.message);
        // dispatch(setSowInfo("0", "1", "approved", null, "pending"));
        break;
      case "RESUME UPLOADED":
        // toast.success(message);
        toast.success(data?.message);
        // dispatch(setSowInfo("0", "1", "approved", null, "pending"));
        break;

      default:
        break;
    }
  };

  // Listening to socket messages
  useEffect(() => {
    if (socket && userData?.userId) {
      console.log("socket connection established");
      dispatch({
        type: "STORE_SOCKET_INSTANCE_SUCCESS",
        payload: socket,
      });
      socket.on(userData?.userId, messageListener);
    } else {
      console.log("socket connection failed");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, dispatch]);

  const mainContent = React.useRef(null);
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(
          routes[i].layout + routes[i].mainPath
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/logo.svg")
            .default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props?.location?.pathname)}
          companyName={location?.state?.name}
        />
        <Switch>
          {getRoutes(routes)}
          {/* <Redirect from="*" to="/admin/index" /> */}
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
